/* Variables */

@navLinkWhite: #C3C3C3;
@navLinkWhiteActive: #E5E5E5;
@navLinkBlack: #333333;
@navLinkBlackActive: #1B1B1B;
@navBarBlack: #040404;
@navBarBlackHighlight: lighten(@navBarBlack, 20%);
@colorBrightWhite: #FFFFFF;
@colorLightWhite: #F4F4F4;
@colorWhite: #E5E5E5;
@colorDarkWhite: #C3C3C3;
@colorYellow: #F89406;
@colorGreen: #33AA33;
@colorDarkGreen: #468847;
@colorDarkRed: #D70000;
@colorLightGrey: #D4D4D4;
@colorGrey: #AAAAAA;
@colorDarkGrey: #444444;
@colorLightBlack: #333333;
@colorBlack: #222222;
@colorDarkBlack: #1B1B1B;

@colorStoreGreen: #BDC996;
@colorStoreGreenHighlight: darken(@colorStoreGreen, 10%);
@colorStoreText: @colorDarkGrey;
@colorStoreTextHighlight: @colorStoreText;

@colorStoreSvea: #31aae2;
@colorStoreSveaHighlight: darken(@colorStoreSvea, 10%);

@colorClubRed: #DD4B39; // #D70000; // #D81D38
@colorClubRedHighlight: darken(@colorClubRed, 5%);
@colorClubText: @colorLightWhite;
@colorClubTextHighlight: @colorClubText;

@palette1Base: #166b8c; // Blue-ish
@palette1Highlight: darken(@palette1Base, 10%);
@palette1Contrast: @colorBrightWhite;
@palette1Tint1: #2cbae5;
@palette1Tint2: #309ab2;
@palette1Tint3: #0f8491;
@palette2Base: #119686; // Blue-green-ish
@palette2Highlight: darken(@palette2Base, 10%);
@palette2Contrast: @colorBrightWhite;
@palette2Tint1: #5abaa0;
@palette2Tint2: #46afad;
@palette2Tint3: #33aa66;
@palette3Base: #f28f30; // Orange
@palette3Highlight: darken(@palette3Base, 10%);
@palette3Contrast: @colorBrightWhite;
@palette3Tint1: #f28e30;
@palette3Tint2: #e8640a;
@palette3Tint3: #fcc30b;
@palette4Base: #053f49; // Black-ish
@palette4Contrast: @colorWhite;
@palette5Base: @colorWhite; // White

@colorBackground: @colorWhite;
@colorCanvasBackground: @colorLightWhite;
@colorCanvasForeground: @palette1Base;
@colorCanvasForegroundHighlight: @palette1Highlight;
@colorText: @palette4Base;
@colorTextSubtle: lighten(@colorText, 5%);
@colorTextHighlight: @palette2Base;
@colorActionable: @palette3Base;

@fontSizeTiny: 10px;
@fontSizeSmall: 12px;
@fontSizeNormal: 14px;
@fontSizeLarge: 16px;
@fontSizeLarger: 24px;
@fontSizeHuge: 4em;

/* Resets and defaults */

* {
	margin: 0px;
	padding: 0px;
}

body {
	font-family: Arial,"Helvetica Neue",Helvetica,sans-serif;
	font-size: @fontSizeNormal;
	color: @colorText;
	background: @colorCanvasForeground;

	&.admin {
		font-size: @fontSizeSmall;
	}
}

a {
	color: @palette4Base;
	text-decoration: none;

	&:hover {
		color: lighten(@palette4Base, 10%);
	}


	p &, td &, th &, .text & {
		&:hover {
			text-decoration: underline;
		}
	}

	&.highlight {
		color: @palette2Base;

		&:hover {
			color: @palette2Highlight;
		}
	}
}

ul {
	p &, td &, th & {
		margin: 0px 1em;
	}
}

table {
	border-collapse: collapse;
	border-spacing: 0px;
}

img {
	border: 0;
}

p, h1, h2, h3, h4, h5, h6 {
	margin: 5px 0px;
}

.text {
	font-size: @fontSizeNormal;

	p {
		margin: 10px 0px;
	}
}

h1, h2, h3, h4, h5, h6 {
	line-height: 1.5em;
	font-family: 'Didact Gothic', sans-serif;
}

:focus {
	outline: 0 none;
}

button::-moz-focus-inner {
	border: 0 none;
	padding: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
    -moz-appearance: textfield;
}

@import "responsive.less";
@import "common.less";
@import "button.less";
@import (reference) "mixins.less";
@import "fontawesome/fontawesome.less";
@import "fontawesome/regular.less";
@import "fontawesome/solid.less";
@import "fontawesome/brands.less";

hr {
	clear: both;
	border: none;
	border-bottom: 5px @colorLightGrey solid;
}

section {
	padding-bottom: 10px;
	margin-bottom: 10px;
	border-bottom: 5px @colorLightGrey solid;
}

/* Theme-specific */

/* Layout */

@import "mobile.less";

@media @mediaTablet {
	@import "tablet.less";
}

@media @mediaDesktop {
	@import "desktop.less";
}