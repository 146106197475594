@mediaDesktop: ~"only screen and (min-width: 960px) and (hover: hover)";
@mediaTablet: ~"only screen and (min-width: 720px)";

/* Mobile-first */

.visible-phone {
	display: initial !important;
}

.visible-tablet {
	display: none !important;
}

.visible-desktop {
	display: none !important;
}

.hidden-phone {
	display: none !important;
}

.column-6 {
	width: auto;
	float: none;
}

@media @mediaTablet {
	.visible-phone {
		display: none !important;
	}

	.visible-tablet {
		display: initial !important;
	}

	.hidden-phone {
		display: initial !important;
	}

	.hidden-tablet {
		display: none !important;
	}

	.layout-fluid {
		.column-2 {
			width: 16.666%;
			float: left;
		}

		.column-3 {
			width: 25%;
			float: left;
		}
		.column-4 {
			width: 33.333%;
			float: left;
		}

		.column-5 {
			width: 41.666%;
			float: left;
		}

		.column-6 {
			width: 50%;
			float: left;
		}

		.column-7 {
			width: 58.333%;
			float: left;
		}

		.column-8 {
			width: 66.666%;
			float: left;
		}

		.column-9 {
			width: 75%;
			float: left;
		}
	}
}

@media @mediaDesktop {
	.visible-phone {
		display: none !important;
	}

	.visible-desktop {
		display: initial !important;
	}

	.hidden-phone {
		display: initial !important;
	}

	.hidden-desktop {
		display: none !important;
	}

	.column-2 {
		width: 200px;
		float: left;
	}

	.column-3 {
		width: 300px;
		float: left;
	}

	.column-4 {
		width: 400px;
		float: left;
	}

	.column-5 {
		width: 500px;
		float: left;
	}

	.column-6 {
		width: 600px;
		float: left;
	}

	.column-7 {
		width: 700px;
		float: left;
	}

	.column-8 {
		width: 800px;
		float: left;
	}

	.column-9 {
		width: 900px;
		float: left;
	}

	.column-10 {
		width: 1000px;
		float: left;
	}

	.layout-fluid {
		.column-3 {
			width: 25%;
			float: left;
		}

		.column-4 {
			width: 33.333%;
			float: left;
		}

		.column-5 {
			width: 41.666%;
			float: left;
		}

		.column-6 {
			width: 50%;
			float: left;
		}

		.column-7 {
			width: 58.333%;
			float: left;
		}

		.column-8 {
			width: 66.666%;
			float: left;
		}

		.column-9 {
			width: 75%;
			float: left;
		}
	}

	.column-left {
		float: left;
		clear: left;
	}

	.column-right {
		float: right !important;
		clear: right;
	}
}