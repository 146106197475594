section:not(.separated) {
	border-bottom: none;
}

div#site {

	& > * > .inside {
		width: 1200px;
		margin: auto;
	}

	& > header {
		position: static;
		padding: 0px;

		.inside {
			flex-wrap: nowrap;

			a.logo {
				flex: 0 0 50%;
				display: block;
				text-align: left;

				img {
					width: auto;
					max-height: 70px;
					margin: 10px;
					vertical-align: bottom;
				}
			}

			div.search {
				flex: 0 0 50%;
				width: auto;
				display: flex;
				align-items: center;

				& > div.container {
					margin: 0px;
					width: 100%;
					background: transparent;
					border-bottom: none;

					form {
						width: 100%;
						display: flex;
						height: 40px;
						border-radius: 3px;
						background-color: @colorLightWhite;
						
						input {
							display: block;
							padding: 5px;
							height: 40px;
							font-size: @fontSizeLarger;
							line-height: 30px;
							color: @colorText;

							.mixin-placeholder(@colorText);
						}

						button {
							padding: 0px 10px;
							height: 40px;
							width: auto;
							line-height: 30px;
							border-radius: 0px 3px 3px 0px;
							border: none;
							background: @palette3Base;

							&:hover {
								background: @palette3Highlight;
							}
						}
					}

					div.panel {
						position: absolute;
						left: 0px;
						width: 100%;
						background-color: @colorLightWhite;
						box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.175);
						overflow: auto;
						color: @colorText;

						a {
							color: @colorTextHighlight;
						}
					}
				}
			}
		}
	}

	& > nav {
		display: block;
		z-index: auto;

		& > .inside {
			display: flex;
			min-height: auto;
			padding: 0px;
			margin: auto;
			width: 1200px;
			position: relative;
			overflow-y: visible;

			& > div.item {
				flex-grow: 1;
				border-bottom: none;
				padding: 0px;
				border-bottom: none;
				display: block;

				&:hover {
					background-color: @colorCanvasForegroundHighlight;
				}

				& > a {
					font-size: @fontSizeLarger;
					font-weight: bold;
					padding: 10px;
					display: block;
					text-align: center;
					line-height: 30px;
				}

				&:hover {
					& > a {
						color: @colorWhite;
					}

					& > .panel {
						display: block;
					}
				}

				& > .panel {
					box-sizing: border-box;
					position: absolute;
					top: 100%;
					left: 0px;
					box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.175);
					width: 1200px;
					z-index: 1000;
					overflow: auto;

					&.active {
						display: block;
					}

					& > ul {
						display: grid;
						grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
						row-gap: 10px;
						column-gap: 10px;

						li {
							padding-left: 0px;
							line-height: 2em;
						}
					}

					& > .title > h2 {
						display: inline-block;
						margin-right: 10px;
					}
				}
			}
		}
	}

	& > main {
		background: @colorBackground;

		& > .inside {
			width: 1200px;
			margin: auto;
			padding-top: 0px;

			footer {

			}
		}
	}

	& > footer {
		ul {
			li {
				float: left;
				margin: 0px 10px;
			}
		}
	}
}

/* Components */

/* - Calendar */

.calendar {
	& > header {
		display: block;
		overflow: auto;
		display: flex;

		& > div {
			display: block;
			width: auto;
			padding: 0px 10px;
			flex-grow: 1;
			flex-basis: 0;
			border-left: 1px transparent solid;

			&:last-child {
				border-right: 1px transparent solid;
			}

			&.weekno {
				width: 20px;
				padding: 0px;
				text-align: center;
				flex-grow: 0;
				flex-basis: auto;
			}
		}
	}

	& > div.week {
		overflow: auto;
		display: flex;

		& > div {
			display: block;
			width: auto;
			margin: 0px;
			border-left: 1px @colorLightGrey solid;
			border-top: 1px @colorLightGrey solid;

			&.weekno {
				width: 20px;
				font-weight: bold;
				flex-grow: 0;
				text-align: center;
				background: @colorWhite;
				padding: 5px 0px;
			}

			&.day {
				flex-grow: 1;
				flex-basis: 0;

				&:last-child {
					border-right: 1px @colorLightGrey solid;
				}

				&.filler {
					display: block;
				}

				header {
					float: none;
					font-size: @fontSizeLarge;
					margin: 5px;
					text-align: left;

					small {
						display: none;
					}
				}

				& > div {
					margin-left: 0px;
					display: block;
					overflow-x: hidden;
					white-space: nowrap;
				}
			}
		}

		&:last-child {
			& > div {
				border-bottom: 1px @colorLightGrey solid;
			}
		}
	}
}

div.calendar-week {
	overflow: auto;
	margin: 10px;

	& > header {
		display: flex;
		border-bottom: 3px @colorClubRed solid;

		& > a {
			display: block;
			flex-grow: 1;
			flex-basis: 0;
			background-color: @colorWhite;
			padding: 10px;
			margin-right: 1px;

			&:last-child {
				margin-right: 0px;
			}

			&.active, &.today {
				background-color: @colorClubRed;
				color: @colorWhite;
			}
		}
	}

	& > div.day {
		& > div {
			& > section {
				margin: 10px 0px;
			}
		}
	}
}

/* - Filter nav */

nav.filters {
	&.vertical {
		margin: 10px;

		& > header {
			margin: 10px 0px;
		}

		& > div {
			border: 1px @palette1Highlight solid;
			border-radius: 2px;
			margin: 10px 0px;
		}
	}

	&.horizontal {
		height: 40px;
		display: flex;
		
		& > header {
			display: none;
		}

		& > div {
			width: auto;
			border-bottom: none;
			border-right: 1px @palette1Highlight solid;
			flex-grow: 1;
			flex-basis: 0;

			&:last-child {
				border-right: none;
				width: 180px;
			}

			&.search {
				min-width: 219px;

				& > form > div {
					input {
						width: 175px;
					}
				}
			}

			&.filter {
				.panel {
					&.active {
						position: absolute;
						border: 1px @colorGrey solid;
						border-top: none;
						z-index: 1000;
						right: 0px;
						max-height: 600px;
					}
				}
			}
		}
	}
}

/* - Sections */

.sections {
	& > header {
		margin: 10px 0px;
	}

	section {
		border: 1px @palette1Highlight solid;
		border-radius: 2px;
		margin: 10px;

		&:last-child {
			border-bottom-width: 1px;
		}

		& > header {
			border: 1px @palette1Highlight solid;
			border-width: 1px 0px;

			&:first-child {
				border-top: none;
			}
		}
	}

	&.accordion section {
		margin: 0px 10px;
		border-radius: 0px;
		border-bottom-width: 0px;

		&:first-child {
			border-radius: 2px 2px 0px 0px;
			margin-top: 10px;
		}

		&:last-child {
			border-radius: 0px 0px 2px 2px;
			margin-bottom: 10px;
			border-bottom-width: 1px;
		}
	}
}

/* - Voteable */

.voteable {
	border-top: 1px @colorLightGrey solid;
	padding-top: 10px;
	padding-bottom: 0px !important;
}

/* - Announcement */


.announcement {
	display: block;
	background: #1B1B1B;
	border-bottom: 3px #222222 solid;
	padding: 10px;
	font-size: 20px;
	color: #E5E5E5;
	text-align: center;

	a {
		color: #E5E5E5;
		text-decoration: underline;
	}
}

/* - Tabs */

.tabs {
	ul.nav {
		display: block;
		list-style: none;
		overflow: hidden;
		border-bottom: 3px @navBarBlack solid;
		padding: 0px 10px;
		white-space: nowrap;

		li {
			float: left;
			display: block;
			border: 1px @colorGrey solid;
			border-bottom: 0;
			margin-right: 3px;
			background-color: @colorWhite;
			border-radius: 3px 3px 0px 0px;

			a {
				display: block;
				line-height: 29px;
				padding: 0px 10px;
				font-weight: bold;
				color: @colorBlack;

				&:hover {
					text-decoration: none;
					color: @colorBlack;
				}
			}

			&.active {
				border-color: @navBarBlack;
				background-color: @navBarBlack;

				a {
					color: @colorWhite;

					&:hover {
						color: @colorLightWhite;
					}
				}
			}
		}
	}

	a.accordion {
		display: none;
	}
}

/* - Inline navigation */

nav.inline-navigation {
	display: flex;

	a {
		margin-right: 0px;

		&:last-child {
			margin-right: 3px;
		}

		&:hover {
			background: @palette1Base;
		}
	}
}

/* - Team entry */

/* - Cart button */

#cart-container {
	.popover-container {
		width: 480px;
	}
}

#cart {
	& > a.cart-button {
		& > div.cart-badge {
			border-color: @colorDarkGrey;
		}

		&.active {
			bottom: 10px;
		}
	}

	.panel {
		&.active {
			bottom: 0px;
			right: 35px;
			max-width: 400px;
			height: auto;
			max-height: 300px;
			border-radius: 3px 3px 0px 0px;
		}
	}
}

#debug {
	& > a.debug-button {
		&.active {
			bottom: 70px;
		}
	}

	.panel {
		&.active {
			bottom: 0px;
			right: 35px;
			max-width: 800px;
			height: auto;
			max-height: 300px;
			border-radius: 3px 3px 0px 0px;
		}
	}
}

/* Slideshow */

div.slideshow {
	height: 500px;

	a.slide {
		width: 100%;
		position: absolute;

		div.caption {
			display: block;
			height: auto;
			background: rgb(0, 0, 0);
			background: rgba(0, 0, 0, 0.5);
			text-align: left;

			* {
				color: @colorWhite;
			}

			p {
				display: block;
			}
		}
	}
}

/* - Checkout elements */

.checkout-step {
	.choice-box {
		width: 196px;
		float: left;
	}
}

/* - Small, specific overrides */

.toggle-button {
	&:hover {
		cursor: pointer;
	}
}

/* - Product listings */

.products.grid {
	grid-template-columns: repeat(auto-fit, minmax(250px, 0.33fr));
}


.categories {
	grid-template-columns: repeat(auto-fit, minmax(21%, 0.25fr));
}