.mixin-placeholder(@color) {
	&::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: @color;
	}
	&:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
	   color: @color;
	   opacity: 1;
	}
	&::-moz-placeholder { /* Mozilla Firefox 19+ */
	   color: @color;
	   opacity: 1;
	}
	&:-ms-input-placeholder { /* Internet Explorer 10-11 */
	   color: @color;
	}
	&:placeholder-shown { /* Standard (https://drafts.csswg.org/selectors-4/#placeholder) */
	  color: @color;
	}
}