/* Layout */

.two-cols {
	float: left;
	width: 50%;
}

.container {
	margin: 10px;
}

.float-left {
	float: left !important;
}

.float-right {
	float: right !important;
}

.sticky {
	position: sticky;
	will-change: transform;
    top: 0;
}

.clear {
	clear: both;
	display: block;
}

/* Visibility */

.collapsed {
	display: none;
}

.no-overflow {
	overflow: hidden;

	img, iframe {
		max-width: 100%;
	}
}

/* Text */

.align-left {
	text-align: left !important;
}

.align-center {
	text-align: center !important;
}

.align-right {
	text-align: right !important;
}

.format-bold {
	font-weight: bold !important;
}

.format-normal {
	font-weight: normal !important;
}

.format-italic {
	font-style: italic !important;
}

.format-subtle {
	font-size: 0.8em;
	opacity: 0.7;
}

.format-line-through {
	text-decoration: line-through !important;
}

.size-tiny {
	font-size: @fontSizeTiny;
}

.size-small {
	font-size: @fontSizeSmall;
}

.size-normal {
	font-size: @fontSizeNormal;
}

.size-large {
	font-size: @fontSizeLarge;
}

.size-larger {
	font-size: @fontSizeLarger;
}

.size-huge {
	font-size: @fontSizeHuge;
}

.color-yellow {
	color: @colorYellow !important;
}

.color-red {
	color: @colorDarkRed !important;
}

.color-green {
	color: @colorGreen !important;
}

.color-grey {
	color: @colorGrey !important;
}

.no-wrap {
	white-space: nowrap;
}

.hint-clickable {
	color: @colorDarkGreen;

	&:hover {
		cursor: pointer;
	}
}

.hint-help {
	color: @colorDarkGreen;
	border-bottom: 1px @colorGrey dotted;

	&:hover {
		cursor: help;
	}
}

/* Buttons and input */

input[type="text"], input[type="password"], input[type="email"], input[type="number"], input[type="file"], input[type="date"], input[type="time"], select, textarea {
	box-sizing: border-box;
	width: 100%;
	padding: 3px;
	border: 1px @colorLightGrey solid;
	border-radius: 2px;
	background: @colorBrightWhite;

	&[required] {
		border-color: @colorGrey;
	}

	&[type=date] {
		width: 140px;
	}

	&.normal {
		width: 200px;
	}

	&.large {
		width: 400px;
	}

	&.small {
		width: 108px;
	}

	&.mini {
		width: 68px;
	}

	&.tiny {
		width: 48px;
	}

	&.single-digit {
		width: 28px;
	}

	&.auto {
		width: auto;
	}

	&.status-processing {
		background-color: #FCF8E3;
		border-color: darken(#FCF8E3, 10%);
		color: #8A6D3B;
	}

	&.status-success {
		background-color: #DFF0D8;
		border-color: #D6E9C6;
		color: #3C763D;
	}

	&.status-error {
		background-color: #F2DEDE;
		border-color: darken(#F2DEDE, 5%);
		color: #A94442;
	}
}

textarea {
	padding: 8px;
}

input:focus, select:focus, textarea:focus {
	border-color: @navBarBlack;

	body.store & {
		border-color: @colorStoreGreen;
	}

	body.club & {
		border-color: @colorStoreGreen;
	}
}

input[type=checkbox], input[type=radio] {
	position: relative;
	top: 1px;
}

input:disabled {
	background: @colorDarkWhite !important;
}