img {
	&.fit {
		width: 100%;
	}
}

div#site {
	width: 100%;
	position: relative;

	& > header {
		width: 100%;
		position: absolute;
		z-index: 1000;
		padding: 0px;
		background: @palette1Base url('/assets/images/hex_tiles.svg') top center repeat;

		.inside {
			display: flex;
			flex-wrap: wrap;
			align-items: center;

			a.logo {
				flex: 1 1;
				display: block;
				text-align: center;

				img {
					width: 100%;
					max-height: 44px;
					margin: 3px 0px;
					vertical-align: bottom;
				}
			}

			a.toggle-button {
				flex: 0 0 50px;
				display: block;
				float: left;
				height: 50px;
				width: 50px;
				text-align: center;
				line-height: 50px;
				font-size: @fontSizeLarger;
				color: @palette1Contrast;

				&:hover, &.active {
					background-color: @palette1Highlight;
				}
			}

			div.search {
				flex: 1 1 100%;
				width: 100%;
				display: none;

				&.active {
					display: block;
				}
				
				& > div.container {
					margin: 0px;
					position: relative;
					background-color: @palette1Base;
					color: @palette1Contrast;
					border-bottom: 1px @palette1Highlight solid;

					a {
						color: @palette1Contrast;
					}

					& form {
						width: 100%;
						display: flex;
						height: 50px;
						overflow: auto;
						background: @palette1Highlight;

						input {
							display: block;
							flex: 1 1;
							line-height: 50px;
							padding: 0px 10px;
							margin: 0px;
							border: 0px;
							background: transparent;
							font-size: @fontSizeLarge;
							color: @palette1Contrast;

							.mixin-placeholder(@palette1Contrast);
						}

						button {
							flex: 0 0 50px;
							-webkit-appearance: none;
							display: block;
							height: 50px;
							width: 50px;
							margin: 0px;
							padding: 0px;
							border: 0px;
							line-height: 50px;
							text-align: center;
							font-size: @fontSizeLarger;
							background-color: @palette1Base;
							color: @palette1Contrast;
						}
					}

					& > div.panel {
						display: none;
						z-index: 1000;

						ul.results {
							list-style: none;

							li {
								overflow: auto;
								display: flex;
								align-items: center;
								padding: 5px 0px;
								border-bottom: 1px @colorLightGrey solid;

								& > img {
									flex: 0 0 49px;
									display: block;
									width: 49px;
									height: 49px;
								}

								& > .content {
									flex: 1 1;
									margin: 0px 10px;
									text-overflow: ellipsis;
									overflow-x: hidden;
									white-space: nowrap;

									small {
										display: block;
										font-size: @fontSizeTiny;
									}
								}

								& > .price {
									flex: 0 0 85px;
								}
							}
						}
					}
				}
			}
		}
	}

	& > nav {
		display: none;
		background: @colorCanvasForeground;
		z-index: 100;

		&.active {
			display: block;
			position: absolute;
			width: 242px;
			min-height: 100%;
			top: 50px;
			left: 0px;
			overflow-y: scroll;
		}

		& > .inside > div.item {
			display: flex;
			flex-wrap: wrap;

			&.active {
				background-color: @colorCanvasForegroundHighlight;
			}

			ul {
				list-style: none;
			}

			& > a {
				flex: 1 1;
				display: block;
				padding: 0px 15px;
				line-height: 40px;
				font-family: 'Didact Gothic', sans-serif;
				border-bottom: 1px rgba(0, 0, 0, 0.175) solid;

				color: @palette1Contrast;
				
				&:hover {
					color: @palette1Contrast;
				}

				&.toggle-button {
					padding: 0px;
					text-align: center;
					width: 40px;
					flex: 0 0 40px;

					& > i.inactive {
						display: inline-block;
					}

					& > i.active {
						display: none;
					}

					&.active {
						background-color: @palette1Highlight;

						& > i.inactive {
							display: none;
						}

						& > i.active {
							display: inline-block;
						}
					}
				}
			}

			& > .panel {
				display: none;
				flex: 1 1 100%;
				padding: 10px;
				background-color: @colorLightWhite;

				&.active {
					display: block;
				}

				li {
					line-height: 40px;

					ul {
						padding-left: 5px;
					}
				}

				h2, h3 {
					line-height: 2em;

					img {
						height: 1.5em;
						margin: 5px;
						float: left;
						vertical-align: bottom;
					}
				}
			}
		}
	}

	& > main > .inside {
		padding-top: 50px;
		background: @colorCanvasBackground;
		display: block;

		footer {
			color: #666666;
			font-size: 10px;

			img {
				vertical-align: bottom;
				margin: 3px 6px;
			}
		}
	}

	& > footer {
		background-color: @colorCanvasForeground;
		color: @colorWhite;
		overflow: auto;

		a {
			color: @colorWhite;
		}

		ul {
			list-style: none;
		}
	}
}

/* Components */

/*  - Alerts */

.alert {
	background-color: #F2DEDE;
	padding: 10px;
	color: #A94442;
	display: block;

	&.bubble {
		margin: 10px;
		border-radius: 5px;
		border: 1px darken(#F2DEDE, 5%) solid;
	}

	&.badge {
		display: inline-block;
		padding: 1px 4px 2px;
		border: 1px darken(#F2DEDE, 5%) solid;
		border-radius: 3px;
		font-weight: bold;
		line-height: 14px;
	}

	&.warning {
		background-color: #FCF8E3;
		border-color: darken(#FCF8E3, 10%);
		color: #8A6D3B;
	}

	&.success {
		background-color: #DFF0D8;
		border-color: #D6E9C6;
		color: #3C763D;
	}

	&.information {
		background-color: #cce5ff;
		border-color: #b8daff;
		color: #004085;
	}
}

/* - Bullet */

.bullet {
	display: inline-block;
	margin: 2px 0px;
	vertical-align: bottom;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: #999999;

	&.color-green {
		background-color: #309F35;
	}

	&.color-yellow {
		background-color: #FFCC00;
	}

	&.color-green-yellow {
		background: linear-gradient(135deg, #309f35 0%,#309f35 50%,#ffcc00 50%,#ffcc00 100%);
	}

	&.color-green-red {
		background: linear-gradient(135deg, #309f35 0%,#309f35 50%,#d70000 50%,#d70000 100%);
	}

	&.color-blue {
		background-color: #336699;
	}

	&.color-red {
		background-color: #D70000;
	}
}

/* - Announcement */

.announcement {
	display: none;
}

/* - Tabs */

.tabs {
	ul.nav {
		display: none;
	}

	a.accordion {
		display: block;
		line-height: 40px;
		background: @palette1Base url(/img/icons/arrow_right_white.png) right 13px center no-repeat;
		padding: 0px 15px;
		font-size: @fontSizeLarge;
		text-transform: uppercase;
		color: @colorLightWhite;
		font-weight: bold;
		border-top: 1px @palette1Highlight solid;

		&:last-child {
			border-top: 1px @palette1Highlight solid;
		}

		&.active {
			background-color: @palette1Highlight;
			background-image: url(/img/icons/arrow_down_white.png);
			background-position: right 10px center;
		}
	}
}

/* - Inline navigation */

nav.inline-navigation {
	a {
		margin: 3px;
		color: @colorDarkGrey;
		background: @colorLightGrey;
		border-radius: 3px;
		padding: 10px 5px;
		display: block;
		flex-grow: 1;
		flex-basis: 0;
		text-align: center;
		font-size: @fontSizeNormal;
		font-weight: bold;
		text-transform: uppercase;
		overflow: hidden;

		&:hover {
			background: @palette1Base;
		}
	}
}

/* - List header */

header.list-header {
	display: flex;
	z-index: 10;
	background: @colorCanvasBackground;
}

/* - Product page */

.product {
	.media {
		height: 400px;

		img {
			max-height: 100%;
			max-width: 100%;
		}
	}

	.price {
		font-size: @fontSizeHuge;
		line-height: 2.2em;
		font-weight: bold;
	}
}

/* - Product listings */

.products {
	.item {
		.image {
			position: relative;
			img {
				border-radius: 5px;
				width: 100%;
				background: #FFFFFF;
			}

			.discount {
				position: absolute;
				top: -10px;
				right: -10px;
				width: 50px;
				height: 50px;
				border-radius: 50%;
				background: #D70000;
				border: 3px @colorWhite solid;
				line-height: 50px;
				text-align: center;
				color: @colorWhite;
				font-size: 14px;
				font-weight: bold;
				box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.2);
			}
		}

		.information {
			& > div {
				margin: 5px 0px;
			}

			.title a {
				word-wrap: break-word;
				text-overflow: ellipsis;
				line-height: 2.5ex;
				max-height: 5ex;
		        display: -webkit-box;
		        -webkit-line-clamp: 2;
		        -webkit-box-orient: vertical;
				overflow: hidden;
			}

			.subtitle {
				font-size: 0.9em;
				color: @colorTextSubtle;

				img {
					height: 1em;
					vertical-align: bottom;
				}
			}

			.stock, .buyin {
				font-size: 0.9em;
			}
		}

		.price {
			font-size: 16px;
			font-weight: bold;
			text-align: right;
			white-space: nowrap;
		}

		.cart {
			.button {
				min-width: auto;
				width: 100%;
				padding-left: 0px;
				padding-right: 0px;
				font-size: 16px;
				font-weight: normal;
			}
		}
	}

	&.grid {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
		gap: 10px 10px;

		.item {
			flex: 0 0 50%;
			display: flex;
			flex-direction: column;

			.title {
				text-align: center;
			}

			.subtitle {
				text-align: center;
			}

			.purchase {
				margin-top: auto;
				overflow: auto;

				& > div {
					margin: 10px;
					text-align: center;
				}

				.price {
					display: none;
				}
			}
		}
	}

	&.list {
		.item {
			padding: 10px 0px;
			display: flex;
			border-bottom: 1px @colorLightGrey solid;

			&:last-child {
				border-bottom: none;
			}

			.label {
				font-weight: bold;
			}

			.image {
				flex: 0 0 140px;

				img {
					width: 100%;
				}
			}

			.information {
				flex-grow: 10;
				margin: 0px 10px;
				overflow: auto;
			}

			.purchase {
				flex: 0 0 60px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				.cart {
					.button {
						width: auto;
						padding: 6px;

						.label {
							display: none;
						}
					}
				}
			}
		}
	}

	&.light {
		.item {
			.information {
				.stock, .buyin {
					display: none;
				}
			}
		}

		&.list {
			.image {
				flex-basis: 65px;
			}
		}
	}
}

/* - Categories listing */

.categories {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
	gap: 10px 10px;

	& > .item {
		display: block;
		text-align: center;

		img {
			box-sizing: border-box;
			padding: 10px;
			width: 100%;
			background: @colorBrightWhite;
		}

		p {
			color: @colorTextHighlight;
		}
	}
}

/* - Team entry */

.team {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
	gap: 10px 10px;

	.person {
		display: block;

		img {
			display: block;
			border-radius: 50%;
			margin: 10px auto;
		}

		ul.skills {
			list-style-type: none;

			li {
				margin: 3px;
			}
		}
	}
}

/* - Store entry */

.team {
	.store {
		text-align: center;

		img {
			display: block;
			border-radius: 50%;
			margin: 10px auto;
			border: 4px @palette1Base solid;
		}
	}
}

/* - Order entry */

.order {
	overflow: auto;
}

/* - Blog entry */

.entry {
	.links {
		margin: 5px 0px;

		span {
			font-size: 0.8em;
			color: @colorDarkGrey;
			padding: 0px 5px;
			border-right: 1px @colorDarkWhite solid;

			&:last-child {
				border-right: none;
			}

			img {
				vertical-align: bottom;
			}
		}
	}

	.icon {
		float: left;
		width: 50px;
	}

	.content {
		margin-left: 60px;
	}
}

div.entry div.links {
	margin: 5px 0px;
}

div.entry div.links span {
	font-size: 10px;
	color: #666666;
	padding: 0px 5px;
	border-right: 1px #D4D4D4 solid;
}

div.entry div.links span img {
	vertical-align: bottom;
}

div.entry div.links span:last-child {
	border-right-width: 0px;
}

div.entry ul, div.entry ol {
	list-style: inherit;
	margin-left: 20px;
}

/* - Voteable */

.voteable {
	overflow: auto;
	
	& > header {
		& > .info {
			overflow: auto;

			& > img {
				float: left;
				width: 50px;
			}

			& > div {
				margin-left: 60px;
			}
		}

		& > .votes {
			float: right;
			margin: 5px;

			& > .vote {
				display: inline-block;
				height: 16px;
				line-height: 16px;
				background: transparent url() top left no-repeat;
				margin: 0px 5px;
				padding-left: 36px;

				&.up {
					background-image: url(/img/icons/vote_up.png);

					&.active {
						background-image: url(/img/icons/vote_up_active.png);
					}
				}

				&.down {
					background-image: url(/img/icons/vote_down.png);

					&.active {
						background-image: url(/img/icons/vote_down_active.png);
					}
				}
			}
		}
	}

	& > .content {
		margin: 5px 0px;
	}
}

/* - Table */

table.table {
	width: 100%;

	&.auto {
		width: auto;
	}

	&.well {
		thead {
			position: sticky;
			top: 0px;
			background: @colorCanvasBackground;
			z-index: 100;
		}
		th {
			text-align: left;
		}

		tbody, tfoot {
			border: 1px @colorLightGrey solid;
			background: @colorWhite;

			tr {
				border: 1px @colorLightGrey solid;
				border-width: 0px 0px 1px 0px;
			}
		}

		&.indicator {
			tbody, tfoot {
				tr {
					border-left: 5px @colorLightGrey solid;
				}
			}
		}

		tfoot {
			border-top: none;
		}
	}

	&.specs {
		th {
			font-weight: normal;
			text-align: right;
			white-space: nowrap;
			min-width: 20%;
			max-width: 150px;

			&.header {
				text-align: left;
				font-weight: bold;
			}
		}

		td {
			width: 100%;
		}

		thead {
			th {
				text-align: left;
				font-weight: bold;
			}
		}
	}

	tr {
		th, td {
			padding: 3px 6px;
			white-space: nowrap;

			&.wrap {
				white-space: normal;
			}

			/*&.ellipsis {
				text-overflow: ellipsis;
				overflow-x: hidden;
				white-space: nowrap;
				display: block;
			}*/

			&.expand {
				width: 100%;
			}

			&.disabled {
				color: @colorGrey;

				* {
					color: @colorGrey;
				}
			}

			img {
				vertical-align: bottom;
			}
		}

		&.disabled {
			color: @colorGrey;

			* {
				color: @colorGrey;
			}
		}
	}

	th.icons, td.icons {
		i {
			background: transparent url(/img/icons/glyphicons/glyphicons-46-calendar.png) center center no-repeat;
			background-size: contain;
			display: inline-block;
			margin: 0px 6px;
			width: 16px;
			height: 16px;
		}
	}
}

/* - Lists */

ul {
	&.unstyled {
		list-style: none;
	}

	&.highlight {
		list-style: none;

		li {
			margin: 2px 3px 2px 0px;
			padding: 3px;

			&:hover, &.active {
				background-color: @colorWhite;
			}
		}

		&.tags {
			li {
				&.primary {

				}

				&.secondary {
					/*display: none;

					&.active {
						display: block;
					}*/
				}

				a {
					&.active {
						font-weight: bold;
					}
				}
			}
		}
	}

	&.icons {
		list-style: none;
		width: 100%;

		li {
			padding-left: 24px;
			background: transparent url(/img/icons/glyphicons/glyphicons-46-calendar.png) 4px center no-repeat;
			background-size: 16px auto;
			margin: 5px 3px;
			line-height: 16px;
			position: relative;
			overflow: hidden;

			&.one-line {
				text-overflow: ellipsis;
				display: block;
				overflow-x: hidden;
				white-space: nowrap;
			}
		}

		&.big {
			li {
				background-position: center 10px;
				background-size: auto 24px;
				padding-left: 0px;
				margin: 10px 0px;
				
				& > a {
					display: block;
					padding: 34px 0px 10px 0px;
					text-align: center;
				}

				.actions {
					text-align: center;
					margin: 0px 10px 10px 10px;
				}

				&.active {
					background-color: @colorWhite;
					border-radius: 3px;
				}
			}
		}
	}

	&.no-wrap li {
		white-space: nowrap;
	}

	&.crumbs {
		padding: 10px;
		overflow: auto;

		li {
			float: left;
			display: block;
			margin-right: 5px;
			color: @colorGrey;
		}
	}

	&.comments {
		list-style: none;

		li {
			overflow: auto;
			padding: 0px 5px;
			margin: 10px 0px;
		}
	}
}

/* - Icons */

.icons {
	.calendar {
		background-image: url(/img/icons/glyphicons/glyphicons-46-calendar.png) !important;
	}

	.phone {
		background-image: url(/img/icons/glyphicons/glyphicons-443-earphone.png) !important;
	}

	.email {
		background-image: url(/img/icons/glyphicons/glyphicons-11-envelope.png) !important;
	}

	.shop {
		background-image: url(/img/icons/glyphicons/glyphicons-453-shop.png) !important;
	}

	.time {
		background-image: url(/img/icons/glyphicons/glyphicons-55-clock.png) !important;
	}

	.cash {
		background-image: url(/img/icons/glyphicons/glyphicons-38-coins.png) !important;
	}

	.card {
		background-image: url(/img/icons/glyphicons/glyphicons-268-credit-card.png) !important;
	}

	.invoice {
		background-image: url(/img/icons/glyphicons/glyphicons-539-invoice.png) !important;
	}

	.comment {
		background-image: url(/img/icons/glyphicons/glyphicons-245-conversation.png) !important;
	}

	.package {
		background-image: url(/img/icons/glyphicons/glyphicons-412-package.png) !important;
	}

	.search {
		background-image: url(/img/icons/glyphicons/glyphicons-28-search.png) !important;
	}

	.barchart {
		background-image: url(/img/icons/glyphicons/glyphicons-42-charts.png) !important;
	}

	.user {
		background-image: url(/img/icons/glyphicons/glyphicons-4-user.png) !important;
	}

	.cargo {
		background-image: url(/img/icons/glyphicons/glyphicons-60-cargo.png) !important;
	}

	.bomb {
		background-image: url(/img/icons/glyphicons/glyphicons-290-bomb.png) !important;
	}

	.check {
		background-image: url(/img/icons/glyphicons/glyphicons-153-check.png) !important;
	}

	.edit {
		background-image: url(/img/icons/glyphicons/glyphicons-151-edit.png) !important;
	}

	.pen {
		background-image: url(/img/icons/glyphicons/glyphicons-236-pen.png) !important;
	}

	.truck {
		background-image: url(/img/icons/glyphicons/glyphicons-59-truck.png) !important;
	}

	.tags {
		background-image: url(/img/icons/glyphicons/glyphicons-67-tags.png) !important;
	}

	.screen {
		background-image: url(/img/icons/glyphicons/glyphicons-87-display.png) !important;
	}

	.target {
		background-image: url(/img/icons/glyphicons/glyphicons-473-target.png) !important;
	}

	.image {
		background-image: url(/img/icons/glyphicons/glyphicons-139-picture.png) !important;
	}

	.users {
		background-image: url(/img/icons/glyphicons/glyphicons-500-family.png) !important;
	}

	.list {
		background-image: url(/img/icons/glyphicons/glyphicons-30-notes-2.png) !important;
	}

	.expand {
		background-image: url(/img/icons/glyphicons/glyphicons-368-expand.png) !important;
	}

	.collapse {
		background-image: url(/img/icons/glyphicons/glyphicons-369-collapse.png) !important;
	}

	.star {
		background-image: url(/img/icons/glyphicons/glyphicons-50-star.png) !important;
	}

	.podium {
		background-image: url(/img/icons/glyphicons/glyphicons-329-podium.png) !important;
	}
}

/* - Calendar */

.calendar {
	& > header {
		display: none;
	}

	& > div.week {
		overflow: auto;

		& > div {
			overflow: auto;
			margin-bottom: 10px;

			&.day {
				&.filler {
					display: none;
				}

				&.disabled {
					header {
						color: @colorGrey;

						a {
							color: @colorGrey;
						}
					}
				}

				&.sunday {
					header {
						color: @colorDarkRed;

						a {
							color: @colorDarkRed;
						}
					}
				}

				&.today {
					background: @colorWhite;
				}

				header {
					float: left;
					width: 40px;
					text-align: center;
					font-size: @fontSizeLarger;

					small {
						display: block;
						font-size: @fontSizeNormal;
					}
				}

				& > div {
					margin-left: 50px;
				}
			}
		}
	}
}

div.calendar-week {
	& > header {
		display: none;
	}

	& > div.day {
		& > div {
			display: none;

			&.active {
				display: block;
			}

			& > section {
				overflow: auto;
				margin: 10px;
				border-bottom: 3px @colorLightGrey solid;

				& > img {
					display: block;
					width: 75px;
					float: left;
					vertical-align: bottom;
				}

				& > div {
					margin-left: 75px;
					padding: 1px 10px;

					a {
						color: @colorClubRed;
					}

					& > .links {
						margin: 5px 0px;
					}
				}
			}
		}
	}
}

/* - Focus Item */

div.focus-item {
	overflow: auto;
	padding: 5px 0px;
	position: relative;

	div.icon {
		height: 100px;
		width: 100px;
		float: left;
		overflow: hidden;
		position: relative;

		a {
			width: 49px;
			height: 49px;
			margin: 0px 1px 1px 0px;
			float: left;
			display: block;
			line-height: 49px;
			text-align: center;
			background: @colorBrightWhite;
			position: relative;

			img {
				width: 49px;
				height: 49px;
				vertical-align: bottom;
			}

			span.overlay {
				position: absolute;
				display: none;
				top: 0px;
				left: 0px;
				width: 100%;
				height: 100%;
				background: rgba(0,0,0,0.5);
				font-weight: bold;
				color: @colorWhite;
			}

			&:hover span.overlay {
				display: block;
			}
		}
	}

	div.content {
		margin: 5px 5px 5px 110px;
	}
}

/* - Pagination */

ul.pagination {
	list-style: none;
	margin: 0px;
	padding: 0px;
	overflow: auto;

	li {
		float: left;

		a, span {
			display: block;
			margin: 5px 2px;
			padding: 0px 5px;
			min-width: 10px;
			line-height: 20px;
			text-align: center;
			overflow: auto;
			border-radius: 2px;

			&.active {
				background-color: @palette2Base;
				color: @palette2Contrast;
			}
		}
	}
}

/* - Navigation */

ul.navigation {
	margin: 0px;
	padding: 0px;
	overflow: auto;
	list-style-type: none;

	li {
		margin: 0px 10px 1px 10px;
		padding: 0px;

		&:last-child {
			border-bottom: none;
		}

		& > a {
			display: block;
			border-left: 3px @colorGrey solid;
			padding: 0px 10px;
			line-height: 30px;
			text-transform: uppercase;

			&:hover {
				background: #E5E5E5;
			}
		}

		&.child {
			& > a {
				padding-left: 30px;
			}
		}

		&.active {
			& > a {
				border-left-color: @colorBlack;
				font-weight: bold;
			}
		}
	}
}

/* - Admin navigation */

nav.admin {
	& > ul {
		list-style-type: none;

		li {
			padding: 5px;
			margin: 10px;
			text-align: center;

			&.active {
				background: @colorWhite;
				border-radius: 3px;
			}

			& > a > i {
				font-size: 40px;
				color: @colorLightBlack;
			}
		}
	}
}

/* - Tree navigation */

nav.tree {
	margin-right: 10px;

	& > h2 {
		margin: 10px 0px 10px 10px;
	}

	ul {
		margin: 0px;
		padding: 0px;
		overflow: auto;
		list-style-type: none;

		& > li {
			margin: 0px 0px 1px 10px;
			padding: 0px;

			&:last-child {
				border-bottom: none;
			}

			& > a {
				display: block;
				padding: 0px 10px;
				line-height: 30px;

				&:hover {
					background: #E5E5E5;
				}

				& > img {
					vertical-align: bottom;
					max-width: 16px;
					max-height: 16px;
					margin-bottom: 7px;
					margin-right: 5px;
				}
			}

			&.active {
				& > a {
					border-left-color: @palette1Base;
					font-weight: bold;
				}
			}
		}
	}
}

/* - Slideshow */

div.slideshow {
	display: block;
	position: relative;

	a.slide {
		display: block;

		img {
			vertical-align: bottom;
		}

		div.caption {
			position: absolute;
			bottom: 0px;
			display: flex;
			justify-content: center;
			align-content: center;
			flex-direction: column;
			text-align: center;
			height: 100%;
			width: 100%;

			* {
				color: @colorWhite;
				text-shadow: 2px 2px rgba(0,0,0,0.4);
			}

			p {
				display: none;
			}
		}
	}
}

/* - Cart button */

#cart-container {
	position: fixed;
	bottom: 10px;
	right: 10px;
	width: 50px;
	height: 50px;

	transform: translateZ(0);
	z-index: 10000;

	& > a.cart-button {
		display: block;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		box-shadow: 0 1px 1px rgba(200,200,200,0.4) inset,1px 1px 3px rgba(0,0,0,0.2);
		background: @palette1Base url(/img/icons/glyphicons/glyphicons-203-shopping-cart.png) center center no-repeat;

		:hover {
			text-decoration: none;
			cursor: pointer;
		}

		& > div.cart-badge {
			position: absolute;
			top: -5px;
			right: -5px;
			z-index: 100;
			height: 21px;
			width: 21px;
			border-radius: 50%;
			background-color: @palette3Base;
			color: @colorWhite;
			font-weight: bold;
			text-align: center;
			line-height: 19px;
		}
	}

	.popover-container {
		position: absolute;
		right: 100%;
		bottom: 0px;
		width: 100%;
		z-index: 100;

		.popover {
			margin: 0px 10px 0px 0px;
			border: 1px #D4D4D4 solid;
			border-radius: 3px;
			background: #FFFFFF;
			position: relative;

			.arrow {
				position: absolute;
				bottom: 19px;
				right: -6px;
				width: 6px;
				height: 11px;
				background: transparent url('/img/clean/arrow_right.png') top left no-repeat;
			}

			.close-button {
				position: absolute;
				top: -12px;
				right: -12px;
				display: block;
				width: 24px;
				height: 24px;
				line-height: 24px;
				text-align: center;
				font-size: 20px;
				font-weight: bold;
				color: #EEEEEE;
				background: #1B1B1B;
				border-radius: 13px;
				cursor: pointer;
			}

			h3 {
				text-transform: uppercase;
				font-size: 14px;
				color: #666666;
				margin: 5px 0px;
				padding: 0px;
				cursor: pointer;
			}

			.scrollarea {
				max-height: 300px;
				overflow-y: scroll;
			}
		}
	}
}

#cart {
	z-index: 10000;
	
	& > a.cart-button {
		display: block;
		position: fixed;
		bottom: 10px;
		right: 10px;
		width: 50px;
		height: 50px;
		line-height: 50px;
		text-align: center;
		font-size: @fontSizeLarger;
		color: @palette1Contrast;
		z-index: 10000;
		border-radius: 50%;
		box-shadow: 0 1px 1px rgba(200,200,200,0.4) inset,1px 1px 3px rgba(0,0,0,0.2);
		background: @palette1Base;

		&:hover {
			text-decoration: none;
			cursor: pointer;
		}

		& > div.cart-badge {
			position: absolute;
			top: -5px;
			right: -5px;
			z-index: 100;
			height: 21px;
			width: 21px;
			border-radius: 50%;
			font-size: @fontSizeNormal;
			background-color: @palette3Tint2;
			color: @colorWhite;
			font-weight: bold;
			text-align: center;
			line-height: 19px;
		}

		&.active {
			bottom: 225px;
			background-color: @palette1Highlight;
		}
	}

	.panel {
		display: none;
		z-index: 10000;

		&.active {
			display: block;
			position: fixed;
			bottom: 0px;
			width: 100%;
			height: 250px;
			background: @palette1Base;
			overflow-y: scroll;
			color: @palette1Contrast;
		}

		a {
			color: @palette1Contrast;
		}
	}
}

#debug {
	& > a.debug-button {
		display: block;
		position: fixed;
		bottom: 70px;
		right: 10px;
		width: 50px;
		height: 50px;
		line-height: 50px;
		text-align: center;
		font-size: @fontSizeLarger;
		color: @palette3Contrast;
		z-index: 10000;
		border-radius: 50%;
		box-shadow: 0 1px 1px rgba(200,200,200,0.4) inset,1px 1px 3px rgba(0,0,0,0.2);
		background: @palette3Base;

		&:hover {
			text-decoration: none;
			cursor: pointer;
		}

		&.active {
			bottom: 225px;
			background-color: @palette3Highlight;
		}
	}

	.panel {
		display: none;
		z-index: 10000;

		&.active {
			display: block;
			position: fixed;
			bottom: 0px;
			width: 100%;
			height: 250px;
			background: @palette3Base;
			overflow-y: scroll;
			color: @palette3Contrast;
		}

		table.queries {
			font-family: monospace;

			td {
				padding: 3px;
				vertical-align: top;

				ul {
					list-style-type: none;
				}
			}
		}
	}
}

/* - Filter nav */

nav.filters {
	& > header {
		margin: 10px;
	}

	& > div {
		border-bottom: 1px @palette1Highlight solid;
		padding: 0px 10px;

		&:last-child {
			border-bottom: none;
		}

		&.search {
			overflow: auto;
			background-color: @palette1Base;

			& > form > div {
				background-color: @palette1Highlight;
				border-radius: 2px;
				margin: 10px 0px;
				overflow: hidden;

				input {
					display: block;
					margin: 2px 50px 1px 4px;
					border: none;
					background: transparent;
					padding: 0px;
					width: 260px;
					font-size: @fontSizeNormal;
					color: @palette1Contrast;

					.mixin-placeholder(@palette1Contrast);
				}

				button {
					float: right;
					height: 20px;
					line-height: 20px;
					border: none;
					margin: 0px;
					padding: 0px 6px;
					border-radius: 0px 2px 2px 0px;
				}
			}
		}

		&.filter {
			position: relative;
			padding: 0px;

			.label {
				padding: 0px 10px;
				line-height: 40px;
				height: 40px;
				font-weight: bold;
				text-transform: uppercase;
				background: @palette1Base url(/img/icons/arrow_right_white.png) right 13px center no-repeat;
				overflow-x: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				padding-right: 30px;
				color: @palette1Contrast;

				&.active {
					background-image: url(/img/icons/arrow_down_white.png);
					background-color: @palette1Highlight;
					background-position: right 10px center;

				}

				&:hover {
					cursor: pointer;
					background-color: @palette1Highlight;
				}

				.badge {
					background-color: @palette1Contrast;
					color: @palette1Base;
					border-radius: 50%;
					line-height: 1.5em;
					min-width: 1.5em;
					aspect-ratio: 1;
					display: inline-block;
					text-align: center;
				}
			}

			.panel {
				display: none;
				overflow: auto;

				&.active {
					display: block;
					background: @colorLightWhite;
				}
			}
		}
	}
}

/* - Token Input */

.tokeninput {
	list-style: none;
	margin: 0px;
	border: 1px #E5E5E5 solid;
	padding: 3px;
	background: #FFFFFF;
	
	li {
		position: relative;

		input {
			border: none;
			width: 95%;
		}

		&.token {
			background: #E5E5E5;
			padding: 3px;
			border-radius: 2px;
			margin: 0px 0px 2px 0px;

			&.active {
				background: #D4D4D4;
				cursor: pointer;
			}
		}

		ul.drawer {
			position: absolute;
			top: 100%;
			left: -1px;
			margin: -3px -3px 0px -3px;
			width: 100%;
			z-index: 1000;
			border-top: none;
		}
	}
}

/* - Errorbox */

div.errorbox {
	background: transparent url(/img/clean/errorbox_bg.png) top left repeat;
	padding: 20px 0px 10px 0px;

	& > div {
		background: @colorLightWhite;
		overflow: auto;
	}
}

/* - Sections */

.sections {
	& > header {
		margin: 10px;
	}

	section {
		border-bottom: 1px @palette1Highlight solid;
		padding: 0px;
		margin: 0px;
		position: relative;

		&:last-child {
			border-bottom-width: 0px;
		}

		& > header {
			padding: 0px 10px;
			line-height: 40px;
			height: 40px;
			font-weight: bold;
			text-transform: uppercase;
			background-color: @palette1Base;
			overflow-x: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			padding-right: 30px;
			color: @palette1Contrast;
		}

		&.expandable {
			& > header {
				background-image: url(/img/icons/arrow_right_white.png);
				background-position: right 13px center;
				background-repeat: no-repeat;

				&.active {
					background-image: url(/img/icons/arrow_down_white.png);
					background-color: @palette1Highlight;
					background-position: right 10px center;
				}
			}

			&:hover {
				cursor: pointer;
				background-color: @palette1Highlight;
			}

			& > div {
				display: none;
				overflow: auto;

				&.active {
					display: block;
					background: @colorLightWhite;
				}
			}
		}
	}
}

/* - Section Header */

.section-header {
	display: block;
	padding: 10px 15px;
	margin: 0px;
	background: @palette1Base;
	color: @palette1Contrast;

	a {
		color: inherit;
	}

	.toggle-button {
		float: right;
		display: block;
		height: 30px;
		width: 30px;
		background: transparent url(/img/icons/arrow_down_white.png) center center no-repeat;

		&.active {
			background-image: url(/img/icons/arrow_right_white.png);
		}
	}
}

/* - Card */

.card-container {
	display: block;
	color: @palette1Contrast;
	margin: 5px;
	border: 2px @palette1Base solid;
	border-radius: 3px;
	padding: 10px;
}

/* - Checkout elements */

ul.choice-list {
	list-style-type: none;

	& > li {
		border-bottom: 1px @colorLightGrey solid;

		padding: 0px 10px;
		line-height: 40px;
		font-weight: bold;
		overflow: auto;

		&:last-child {
			border-bottom-width: 0px;
		}

		&.category {
			font-weight: bold;
			text-transform: uppercase;
			border-color: @colorGrey;

			&.active {
				background-color: @colorWhite;
			}

			& i {
				line-height: inherit;
				font-size: 2em;
			}
		}

		&:not(.non-interactable):not(.disabled):hover {
			text-decoration: none;
			background-color: @colorWhite;
			cursor: pointer;
		}

		&.disabled, &.disabled:hover {
			color: @colorGrey;
			cursor: not-allowed;
		}

		&.active {
			background-color: @colorWhite;
		}

		& > .right {
			float: right;
			font-weight: normal;
		}

		& > .sub {
			line-height: normal;
			font-weight: normal;
			margin-bottom: 10px;

			& img {
				max-width: 100%;
			}
		}

		& > img {
			margin: 10px;
			vertical-align: bottom;

			&.logo {
				height: 15px;
				margin-top: 0px;
				display: block;
			}
		}
	}
}

/* - Small, specific overrides */

.tag-group {
	h3 {
		padding-left: 30px;
		background: transparent url(/img/icons/glyphicons/glyphicons-369-collapse.png) left 4px center no-repeat;

		&.active {
			background-image: url(/img/icons/glyphicons/glyphicons-368-expand.png);
		}
	}

	ul {
		display: none;

		&.active {
			display: block;
		}
	}
}

img.magic-icon {
	height: 16px;
	vertical-align: bottom;
}

/* - Additional filters */

#additional-filters.active {
	display: block !important;
}

/* - Stacked form */

form.stacked {
	label:not(.inline) {
		display: block;
		margin: 6px 0px 3px 0px;
		font-weight: bold;
	}
}

/* - Inset box */

.inset {
	border: 1px @colorLightGrey solid;
	border-radius: 3px;
	background: @colorWhite;
}