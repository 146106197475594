.button {
	border-radius: 2px;
	border: none;
	border-bottom: 2px rgba(0, 0, 0, 0.25) solid;
	cursor: pointer;
	display: inline-block;
	text-align: center;
	background-color: #D4D4D4;
	color: #222222 !important;
	padding: 1px 6px;
	margin: 2px 0px 1px 0px;
	line-height: 16px;
	white-space: nowrap;
	overflow: auto;

	img {
		vertical-align: bottom;
	}

	a&, a&:hover {
		text-decoration: none !important;
	}

	&:hover, &:focus, &.active {
		background-color: #C3C3C3;
	}

	&.wrap {
		white-space: normal;
	}

	body.store &.adapt {
		background-color: @colorStoreGreen;
		color: @colorStoreText;

		&:hover, &:focus, &.active {
			background-color: @colorStoreGreenHighlight;
			color: @colorStoreTextHighlight;
		}
	}

	body.club &.adapt {
		background-color: @colorClubRed;
		color: @colorClubText !important;

		&:hover, &:focus, &.active {
			background-color: @colorClubRedHighlight;
			color: @colorClubTextHighlight !important;
		}
	}
	
	&.large {
		border-radius: 4px;
		padding: 8px 10px;
		font-size: 18px;
		font-weight: bold;
		min-width: 140px;

		a& {
			min-width: 118px;
		}
	}

	&.cozy {
		padding: 6px;
	}

	&.small {
		font-size: 10px;
		line-height: 14px;
		padding: 0px 4px;
	}

	&.action {
		background-color: @palette3Base;
		color: @palette3Contrast !important;

		&:hover, &:focus {
			background-color: @palette3Highlight;
		}
	}

	&.watch {
		background-color: @palette3Tint2;
		color: @palette3Contrast !important;

		&:hover, &:focus {
			background-color: @palette3Tint2;
		}
	}

	&.purchase {
		background-color: @palette2Tint3;
		color: @palette2Contrast !important;

		&:hover, &:focus {
			background-color: @palette2Tint3;
		}
	}

	&.order {
		background-color: @palette3Tint3;
		color: @palette3Contrast !important;

		&:hover, &:focus {
			background-color: @palette3Tint3;
		}
	}

	&.preorder {
		background-color: @palette1Base;
		color: @palette1Contrast !important;

		&:hover, &:focus {
			background-color: @palette1Highlight;
		}
	}

	&.green {
		background-color: #309F35;
		color: #FFFFFF !important;

		&:hover, &:focus {
			background-color: #307C34;
		}
	}

	&.orange {
		background-color: #FFCC00;

		&:hover {
			background-color: #F89406;
		}
	}

	&.red {
		background-color: #D70000;
		color: #FFFFFF !important;
	
		&:hover, &:focus {
			background-color: #B50000;
		}
	}

	&.blue {
		background-color: #336699;
		color: #FFFFFF !important;
	
		&:hover {
			background-color: #225588;
		}
	}

	&.black {
		background-color: #333333;
		color: #FFFFFF !important;
	
		&:hover, &:focus, &.active {
			background-color: #222222;
		}
	}

	&.disabled, &[disabled] {
		background-color: #D4D4D4 !important;
		color: #222222 !important;

		&:hover {
			background-color: #D4D4D4;
			cursor: not-allowed;
		}
	}
}

/* Buttonset */

.buttonset {
	overflow: auto;

	.button {
		float: left;
		display: block;
		min-width: 0px;
		border-radius: 0px;
		-moz-border-radius: 0px;
		-webkit-border-radius: 0px;
		margin: 0px;

		&:first-child {
			border-radius: 2px 0px 0px 2px;
			-moz-border-radius: 2px 0px 0px 2px;
			-webkit-border-radius: 2px 0px 0px 2px;
		}

		&:last-child {
			border-radius: 0px 2px 2px 0px;
			-moz-border-radius: 0px 2px 2px 0px;
			-webkit-border-radius: 0px 2px 2px 0px;
		}
	}
}

/* Pane */

.pane {
	display: none;
}

.pane.active {
	display: block;
}